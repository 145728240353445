import React from "react";
//import Navbar from './Navbar';

function Header() {
    return (
        <div id='main'>
            <div className="name">
                <h2>STEP UP YOUR</h2>
                <h1><span>FITNESS</span>WITH US</h1>
                <p className="details">Build Your Body And Fitness With A Professional Touch </p>
                <div className="header-btns">
                    <a href="#" className="header-btn">JOIN US</a>
                </div>
            </div>
          {/*  <Navbar />
             <div className="name">
                <h1> It's a <span>ReactJS</span> Website</h1>
                <p className="details">Most calenders are designed for teams. This is designed for freelancers who
                    want a simple way to plan their schools</p>
                <div className="header-btns">
                    <a href="#" className="cv-btn">Hire Me</a>
                    <a href="#" className="cv-btn1">Download App</a>
                </div>
            </div> 
            <div className="arrow"></div> */}
        </div>
    )
}

export default Header;