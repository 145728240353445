import React from "react";
import Featurebox from './Featurebox'
import fimage1 from '../images/1.svg'
import fimage2 from '../images/2.svg'
import fimage3 from '../images/3.svg'
import fimage4 from '../images/4.svg'

function Feature() {
    return (
        <div id="features">
            <h1>FEATURES</h1>
            <div className="a-container">
                <Featurebox image={fimage1} title="WeightLifting"/>
                <Featurebox image={fimage2} title="Specific Muscle"/>
                <Featurebox image={fimage3} title="Flex Your Muscle"/>
                <Featurebox image={fimage4} title="Cardio Exercise"/>
            </div>
            {/* <div className="Feature-model">
               <img src={featureimage} alt="feature-image"/> 
            </div>
            <div className="Feature-text">
                <h2>Feature</h2>
                <h3>This Application <span>Software</span>is Art</h3>
                <p>We made this Application software with so much love for those who consider themselves as lovers of art 
                    We understand the true meaning of art hence have inculcated every bit of dynamisms into this work.
                    You are lucky to come by this piece of work because its designed just for princes like you. </p>
                <button>View More Feature</button>
            </div> */}
        </div>
    )
}

export default Feature;