import React from "react";
import aboutimage from '../images/about.png'


function About() {
    return (
        <div id="about">
            <div className="about-image">
                <img src={aboutimage} alt=""/>
            </div>
            <div className="about-text">
                <h1>LEARN MORE ABOUT US</h1>
                <p>We at SUPER GYM are professional in every activities we undertake to all manner of persons
                    We have been working with diverse persons and until now all of them keep coming. 
                    All clients are persistent and seeing results all the time.
                    Enroll with us today. 
                    Visit us on the SUPERJCYBS building @ #412 Chickweed Street, Kaneshie, Apam.
                </p>
                <button>READ MORE</button>
            </div>
        </div>
    )
}

export default About